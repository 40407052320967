@import "vars.scss";
@import "fonts.scss";
@import "flex.scss";

.filled-button {
  @include flex-row(center, center);
  width: 100%;
  height: 42px;
  background: $color-primary;
  border: none;
  border-radius: 20px;
  @include font-style(500, 16px, 100%, $color-white);
}

.filled-button.disabled {
  background: $color-disabled;
  border: none;
  color: $color-white;
  cursor: not-allowed;
}

.not-filled-button {
  @include flex-row(center, center);
  width: 100%;
  height: 42px;
  background: $color-white;
  border: 2px solid $color-primary;
  border-radius: 20px;
  @include font-style(500, 16px, 100%, $color-primary);
}

.not-filled-button:active:not(.disabled) {
  background: $color-primary;
  color: $color-white;
}

.not-filled-button.disabled {
  background: $color-white;
  border: 2px solid $color-disabled;
  border-radius: 20px;
  @include font-style(500, 16px, 100%, $color-disabled);
  cursor: not-allowed;
}

.not-filled-button-2 {
  @include flex-row(left, center);
  padding: 10px 16px;
  gap: 10px;

  width: 100%;
  height: 46px;

  border: 1px solid $color-primary;
  border-radius: 12px;
  background: $color-white;
  @include font-style(500, 16px, 100%, $color-dark);
}

.not-filled-button-2:hover {
  background: $color-light-grey;
}