.gap-2 {
  gap: 2px !important;
}

.gap-4px {
  gap: 4px !important;
}

.gap-8 {
  gap: 8px !important;
}

.gap-12 {
  gap: 12px !important;
}

.gap-14 {
  gap: 14px !important;
}

.gap-16 {
  gap: 16px;
}

.gap-32 {
  gap: 32px;
}

.gap-40 {
  gap: 40px;
}

.gap-64 {
  gap: 64px;
}
