.slide-card {
  position: fixed;
  right: -800px;
  z-index: 90010;
  top: 0;
  transition: 0.2s;
}

.slide-card-visible {
  right: 0;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -100;
}

.background.opaque{
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50001;
  transition: 0.4s;
}
