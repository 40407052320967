@import "~bootstrap/dist/css/bootstrap.css";
@import './app/shared/styles/flex.scss';
@import './app/shared/styles/vars.scss';
@import './app/shared/styles/buttons.scss';
@import './app/shared/styles/slide-card.scss';
@import './app/shared/styles/gap.scss';

$secondaryColor: #01db99;

html,
body {
  height: 100%;
}

* {
  font-family: "Ubuntu", Roboto, sans-serif !important;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Ubuntu", Roboto, sans-serif !important;
  font-style: normal;
}

.container-animation{
  position: relative !important;
}

.animation-new{
  position: absolute;
  width:50px !important;
  height: 18px !important;
  left: 450px;
  top:6px;
}

.width-100{
  width: 100% !important;
}
 
.stroke{
  font-weight: 600 !important;
}

.italic{
  font-style: italic !important;
}

.line.grey{
  border-bottom: 1px solid #adbcce;
}

.underline-hover:hover{
  text-decoration: underline;
}

.display-none{
  display: none !important;
}

.pointer, .arrow{
  cursor: pointer;
}

.invalid-text{
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #E05B33;
}

.asterisk {
  color: #e05b33;
}

.info-message {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: -0.02em;
  color: $color-disabled;
}

.message-error{
  color:#E05B33;
  font-size: 12px;
  font-weight: 600;
}

.msg-warning{
  color: $msg-warning;
  font-size: 12px;
  font-weight: 400;
}

.message-warning{
  color: $msg-warning;
  font-size: 12px;
  font-weight: 600;
}

.message-success{
  color:$color-primary;
  font-size: 12px;
  font-weight: 600;
}

::-webkit-input-placeholder { color: #ADBCCE; } /* WebKit */
::-moz-placeholder { color: #ADBCCE; } /* Firefox 19+ */

.visible-in-mobile, 
.not-visible-in-desktop,
.visible-in-510,
.visible-in-950{
  display: none !important;
}

@media screen and (max-width: 950px) {
  .not-visible-in-desktop{
    display: flex !important;
  }

  .not-visible-in-950{
    display: none !important;
  }

  .visible-in-950{
    display: flex !important;
  }
  
}

@media screen and (max-width: 600px) {
  .visible-in-mobile{
    display: flex !important;
  }

  .not-visible-in-mobile{
    display: none !important;
  }
}

@media screen and (max-width: 510px) {
  .visible-in-510{
    display: flex !important;
  }

  .not-visible-in-510{
    display: none !important;
  }
}


//Angular components
.mat-tab-label {
  font-family: "Ubuntu";
  font-size: 16px;
  height: 32px !important;
  width: 94px !important;
  padding: 0 !important;
  min-width: 110px !important;
}

.mat-tab-header {
  border: none !important;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background: $color-primary;
  height: 4px;
  width: 75px !important;
  margin-left: 17px;
}

//----------------MAT_RADIO---------------\\
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  opacity: 0 !important; /*click effect color change*/
  background-color: black !important;
}

.mat-mdc-radio-button .mdc-radio{
  padding:0 !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle,
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
  border-color: $color-primary !important;
}

.mat-mdc-radio-button.mat-mdc-radio-checked .mat-ripple-element {
  background-color: white !important;
}

.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: $color-primary;
  --mdc-radio-selected-hover-icon-color: $color-primary;
  --mdc-radio-selected-icon-color: $color-primary;
  --mdc-radio-selected-pressed-icon-color: $color-primary;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: $color-primary;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}


.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: $color-primary !important; /*inner circle color change*/
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $color-primary !important; /*outer ring color change*/
}
.mat-radio-label-content {
  padding-left:2px !important;
}


//------------CHECKBOX--------------\\

.mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
  opacity: 0.03 !important;
  background-color: $color-primary !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: $color-primary;
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: $color-primary;
}

//Expansion panel sin bordes
.mat-accordion .mat-expansion-panel:last-of-type {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.mat-accordion .mat-expansion-panel:first-of-type {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

.mat-expansion-panel:not([class*="mat-elevation-z"]) {
  box-shadow: none;
}

.mat-select-arrow {
  color: $color-primary;
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: $color-primary;
}

.mat-tab-body.mat-tab-body-active {
  overflow-y: hidden !important;
}

.mat-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-form-field-flex {
  background: white !important;
  border: 1px solid #ced9da;
  border-radius: 16px;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0 0.75em 0 0.75em !important;
}

.mat-form-field-appearance-fill .mat-select-arrow-wrapper {
  transform: translateY(0%) !important;
}

//CALENDAR
.mat-calendar-arrow {
  fill: #66b245;
}

.mat-calendar-body-selected {
  background-color: #66b245;
}

.mat-tab-body-content::-webkit-scrollbar {
  width: 5px; /* width of the entire scrollbar */
}

.mat-tab-body-content::-webkit-scrollbar-track {
  background: #fff; /* color of the tracking area */
}

.mat-tab-body-content::-webkit-scrollbar-thumb {
  background-color: #fff; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 5px solid $color-primary; /* creates padding around scroll thumb */
}



//SLIDE
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #00c689 !important;
  --mdc-switch-selected-handle-color: #00c689 !important;
  --mdc-switch-selected-hover-state-layer-color: #00c689 !important;
  --mdc-switch-selected-pressed-state-layer-color: #00c689 !important;
  --mdc-switch-selected-focus-handle-color: #00c689 !important;
  --mdc-switch-selected-hover-handle-color: #00c689 !important;
  --mdc-switch-selected-pressed-handle-color: #00c689 !important;
  --mdc-switch-selected-focus-track-color: #adefdb !important;
  --mdc-switch-selected-hover-track-color: #adefdb !important;
  --mdc-switch-selected-pressed-track-color: #adefdb !important;
  --mdc-switch-selected-track-color: #adefdb !important;
}

//DIALOG
.cdk-overlay-container {
  position: fixed;
  z-index: 99999 !important;
}

.mat-dialog-container {
  width: 538px !important;
  height: 386px !important;

  background: #ffffff;
  border-radius: 21px !important;
}


.not-scroll{
  overflow-y: hidden;
  overflow-x: hidden;
}

@media screen and (max-width: 1024px) {
  .not-scroll{
    overflow-y: auto;
    overflow-x: hidden;
  }
}