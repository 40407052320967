@mixin flex-row($justify: center, $align: center) {
  display: flex;
  flex-direction: row;
  justify-content: $justify;
  align-items: $align;
}

@mixin flex-column($justify: center, $align: center) {
  display: flex;
  flex-direction: column;
  justify-content: $justify;
  align-items: $align;
}

.row {
  @include flex-row();
}

.row-between {
  @include flex-row(space-between, center);
}

.row-left {
  @include flex-row(left, center);
}

.row-right {
  @include flex-row(right, center);
}

.row-center {
  @include flex-row(center, center);
}

.column {
  @include flex-column();
}

.column-center {
  @include flex-column(center, center);
}

.column-left {
  @include flex-column(flex-start, flex-start);
}

.column-right {
  @include flex-column(flex-end, flex-end);
}

.column-between {
  @include flex-column(space-between, flex-start);
}