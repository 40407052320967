$color-primary: #00c689;
$color-primary-light: #B3EEDC;
$color-dark: #2e3b4c;
$color-grey: #636969;
$color-black: #000;
$color-white: #fff;
$color-light-grey:#f4f6f9;
$color-grey-dark: #9B9F9F;
$color-invalid: #E05B33;

$color-retired: #f24815;

$color-warning: #FFB800;
$msg-warning: #D7960F;
$color-disabled: #A8B1B7;
$color-line:#adbcce;
$color-line-light:#d9d9d9;

$color-green-dark:#005850;
$color-green-light: #5CA991;
$color-blue: #0a7aff;
$color-blue-dark: #2743d7;
$color-yellow: #D7C20D;
$color-yellow-light: #FFE3A9;
$color-yellow-light-opacity:#ffe3a98f;
$color-orange: #FB6D1D;
$color-buyer-message:#A6B9CF;

$background-table:#f5f7f9;


$color-background-board-to-fix: #DDDEDE;
$traditional: $color-primary;
$pase-cosecha: #4BE2EC;
$porcentaje-producto:#5445B1;
$sin-espacio:#D54BD8;
